import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import AnchorLink from '../components/AnchorLink'
import { Link, graphql } from 'gatsby'
import AUTHORS from '../json/authors.json'
import BackgroundImage from 'gatsby-background-image'
import Dots from '../svg/Dots'

export default function BlogPage({data: { blog: { edges }}}) {
  const root = useClasses()
  const filteredEdges = `${process.env.NODE_ENV}` == 'production' ? edges.filter((edge) => edge.node.frontmatter.published) : edges

  return (
    <Layout>
      <SEO title='Blog' description='Collection of writings from OneOffice experts'/>
      <main className='main' ref={root}>

        <div className="heading-separator"/>
        <h1 className="title">Our Blog</h1>
        <h2 className="subtitle">We love writing ...</h2>
        <p className="description">
          This is a collection of our growing list of articles.
          You will find that we are no-fluff and focus on what matters most. Of course, we are passionate about OneOffice (we'll do our best to curb it in our writings!)
          Don't forget to <AnchorLink to='newsletter'>register to our newsletter</AnchorLink> so you don't miss the next great article.
        </p>

        <div className='my-12 flex flex-wrap relative'>
          <Dots className='w-30 h-30 absolute text-gray-300 -right-9 top-1'/>       
          <Dots className='w-30 h-30 absolute text-gray-300 -left-12 -bottom-12'/>    
          {filteredEdges.map(function({node: { frontmatter : {slug, image, title, description, author}}}, index) {
            const featured = index == 0
            return (
              <div className={'relative mt-10 sm:pt-3 w-full max-w-sm sm:max-w-none ' + (featured ? 'sm:w-full lg:w-2/3' : 'sm:w-1/2 lg:w-1/3')} key={index}>
                <Link to={slug} className={'flex flex-col mr-0 sm:mr-3 h-full overflow-hidden bg-gray-200 rounded group hover:bg-gray-600' + (featured ? ' sm:flex-row items-center sm:pr-3' : '')}>
                  <BackgroundImage className={'h-64 z-1 sm:h-80 bg-center bg-cover rounded-t transform group-hover:scale-110 overflow-hidden duration-300' + (featured ? ' group-hover:-translate-x-4 sm:min-h-full w-full sm:w-1/2 sm:rounded-t-none sm:rounded-l' : ' group-hover:-translate-y-4')} fluid={image.childImageSharp.fluid}/>
                  <div className={'flex-1 z-10 py-6 ' + (featured ? 'pl-8 pr-5' : 'px-6')}>
                    <div className={'font-bold text-primary-dark group-hover:text-white leading-tight ' + (featured ? 'text-2xl' : 'text-xl')}>{title}</div>
                    {featured && <p className='group-hover:text-gray-100 mt-4 text-sm font-semibold text-gray-600 leading-relaxed'>{description}</p>}
                    <div className={'flex ' + (featured ? 'mt-8 items-center' : 'mt-1')}>
                      {featured && <img className='w-12 h-12 rounded-full mr-3' src={'/authors/'+author+'.jpg'} />}
                      <div>
                        <div className={'group-hover:text-gray-200 ' + (featured ? 'font-bold text-gray-700 text-sm' : 'font-light text-gray-600 text-xs')}>{AUTHORS[author].name}</div>
                        {featured && <div className='text-xs pt-1 font-light leading-tight text-gray-600 group-hover:text-gray-200'>{AUTHORS[author].description}</div>}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}    
        </div>

      </main>
    </Layout>
  )
}


export const postsQuery = graphql`
  {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/posts/**/*.md" } }
      sort: { fields: [frontmatter___created], order: DESC }
      limit: 12
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            created(formatString: "MMMM DD, YYYY")
            slug
            description
            published
            author
            image {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`